import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import agent from "../../../api/agent";
import { toast } from "react-toastify";
import { CircularProgress, Grid, Typography } from "@mui/material";
import { ChallengeOrder } from "models/challenge";

export default function ChallengePoints() {
  const [loading, setLoading] = useState(true);

  const [points, setPoints] = useState<number>(0);
  const [challengetable, setChallengetable] = useState<any[]>([]);
  //const [challengeOrders, setChallengeOrders] = useState<ChallengeOrder[]>([]);

  useEffect(() => {
    agent.Challenge.getUserChallengeOrders()
      .then((response: ChallengeOrder[]) => {
        //setChallengeOrders(response);

        //Load user's points
        const _validOrders = response.filter(
          (x) =>
            x.orderStatus === "Afgehandel" || x.orderStatus === "Verwerking"
        );
        const _userspoints = _validOrders.reduce(
          (partialSum, a) => partialSum + a.quantity,
          0
        );

        //Set table and value
        setPoints(_userspoints);

        let tableitems = [];

        let countcolors = 0;

        for (let index = 1; index <= 500; index++) {

          if ((index -1) % 20 === 0 ){

            //Rest when it reach 20.
            if (countcolors >= 20){
              countcolors = 0;
            }

            countcolors++;
          }
          //set image depending on 10, 20, 30, 40, etc.
          let imageurl = "Logo 1.png"

         if (countcolors <= 1){
          //no change to image.
         }
         else if (countcolors <= 2){
          imageurl = "Logo 2.png"
         }
         else if (countcolors <= 3){
          imageurl = "Logo 3.png"
         }
         else if (countcolors <= 4){
          imageurl = "Logo 4.png"
         }
         else if (countcolors <= 5){
          imageurl = "Logo 5.png"
         }
         else if (countcolors <= 6){
          imageurl = "Logo 6.png"
         }
         else if (countcolors <= 7){
          imageurl = "Logo 7.png"
         }
         else if (countcolors <= 8){
          imageurl = "Logo 8.png"
         }
         else if (countcolors <= 9){
          imageurl = "Logo 9.png"
         }
         else if (countcolors <= 10){
          imageurl = "Logo 10.png"
         }

          //items like 1 to 19, from 120 onwards no more prices.
            if (index % 20 !== 0) {
                tableitems.push({ number: index, title: index.toString(), col: 1, image: imageurl });
            }
            else {
                //items like  20, 40, etc. 
                tableitems.push({ number: index, title: index.toString(), col: 1, image: imageurl });

                //No prices after 120
                if (index <= 120) {
                    tableitems.push({
                        number: index,
                        title: "Prys " + (index / 20).toString(),
                        col: 2,
                        image: imageurl
                    });
                }
          }
        }

        if (_userspoints <= 60) {
          setChallengetable(tableitems.slice(0, 63));
        }

        if (_userspoints > 60 && _userspoints <= 120) {
          setChallengetable(tableitems.slice(0, 126));
        }

        if (_userspoints > 120 && _userspoints <= 180) {
          setChallengetable(tableitems.slice(0, 186));
        }

        if (_userspoints > 180 && _userspoints <= 240) {
          setChallengetable(tableitems.slice(0, 246));
        }

        if (_userspoints > 240) {
          setChallengetable(tableitems.slice(0, -1));
        }
      })
      .catch((error) =>
        toast.error("Aggenee, dit het nie gewerk nie, probeer asb weer")
      )
      .finally(() => setLoading(false));
  }, []);

  if (loading)
    return (
      <Box sx={{ textAlign: "left" }}>
        <CircularProgress />
        <Typography>Besig om jou Challenge telbord te laai...</Typography>
      </Box>
    );

  return (
    <Box padding={0} sx={{ overflow: "auto", flexWrap: "nowrap" }}>
      <Grid container spacing={1} minWidth={"800px"}>
        {challengetable.map((item) => (
          <Grid
            item
            xs={item.col}
            alignSelf={"center"}
            sx={{ opacity: points < item.number ? 0.3 : 1 }}
          >
            <Box sx={{ justifyContent: "center", display: "flex" }}>
              <img
                src={"https://kleinbederf.com/images/" + item.image}
                alt={"Kleinbederf Challenge"}
                loading="lazy"
                width={"80%"}
              />
            </Box>
            <Typography paddingY={2} textAlign={"center"}>
              {item.title}
            </Typography>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}
